
import GoogleIcon from "@/static/icons/google.svg";
import { setItem } from "@/utils";
import { mapGetters } from "vuex";
export default {
  name: "LoginPopup",
  components: { GoogleIcon },
  data() {
    return {
      modal: false,
      createMode: false,
    };
  },
  computed: {
    ...mapGetters(["apiUrl"]),
  },
  mounted() {
    this.$nuxt.$on("signInPopup", () => {
      this.modal = !this.modal;
    });
  },
  beforeDestroy() {
    this.$nuxt.$off("signInPopup");
  },
  methods: {
    signin(provider) {
      const { name, query, params } = this.$route;
      setItem(
        "socialLogin",
        JSON.stringify({ name: name || "index", query, params })
      );
      window.open(`${this.apiUrl}/api/auth/social-login/${provider}`, "_self");
    },
  },
};
