
export default {
  name: "StarSelect",
  props: { maxStars: { type: Number, default: 5 }, label: String },
  data() {
    return {
      hoverRating: 0,
    };
  },
  computed: {
    rating: {
      get() {
        return this.$attrs.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    stars() {
      return Array.from({ length: this.maxStars }, (_, i) => i + 1);
    },
  },
  methods: {
    setHoverRating(star) {
      this.hoverRating = star;
    },
    resetHoverRating() {
      this.hoverRating = 0;
    },
  },
};
