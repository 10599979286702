
import { refreshTokens } from "@/utils";

export default {
  name: "DashboardLayout",
  data() {
    return {
      isOpen: false,
    };
  },

  mounted() {
    refreshTokens.call(this);

    this.$nuxt.$on("success", (success) => {
      this.$toast.success(success);
    });

    // Trigger Error Toast Message
    this.$nuxt.$on("error", (message) => {
      this.$toast.error(message);
    });

    //Trigger Info Toast Message
    this.$nuxt.$on("warning", (warning) => {
      this.$toast.warning(warning);
    });
  },

  beforeDestroy() {
    this.$nuxt.$off("success");
    this.$nuxt.$off("error");
    this.$nuxt.$off("warning");
  },

  watch: {
    $route() {
      this.isOpen = false;
    },
  },
};
