
import vClickOutside from "v-click-outside";
import { mapGetters } from "vuex";
export default {
  name: "TopBar",
  directives: { clickOutside: vClickOutside.directive },
  data() {
    return {
      dropdownOpen: false,
      currentDate: new Date(),
      timerInterval: null,
    };
  },
  computed: {
    ...mapGetters(["manager", "owner"]),
  },
  watch: {
    $route(val) {
      this.dropdownOpen = false;
    },
  },
  methods: {
    async logOut() {
      try {
        await this.$auth.logout("laravelJWT");
        this.$router.push({ name: "dashboard-login" });
      } catch (error) {}
    },
  },
};
