
export default {
  name: "Input",
  props: {
    field: Object,
    errors: Object,
    options: Array,
    textarea: Object,
  },
  computed: {
    data: {
      get() {
        return this.$attrs.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    selectAll() {
      try {
        this.data[this.field.name] =
          this.field.options.length === this.data[this.field.name].length
            ? []
            : this.field.options.map(({ value }) => value);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
