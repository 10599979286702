
import { arrayConverter, randomStr } from "@/utils";

export default {
  name: "Checkbox",
  props: {
    id: [String, Number],
    val: [String, Number],
    hideCheckbox: Boolean,
    name: { type: String, default: randomStr() },
  },
  computed: {
    model: {
      get() {
        return this.$attrs.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    isChecked() {
      return arrayConverter(this.model).includes(this.val);
    },
  },
};
