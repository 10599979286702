
import Settings from "@/static/icons/setting.svg";
import { mapGetters } from "vuex";
export default {
  name: "Profile",
  components: { Settings },
  computed: {
    ...mapGetters(["user"]),
    menus() {
      return [
        {
          hide: this.user,
          icon: "fas fa-cubes",
          title: "Dashboard",
          to: { name: "dashboard" },
        },
        {
          icon: "far fa-user",
          title: "My Profile",
          to: { name: "profile" },
        },
        {
          icon: "far fa-bookmark",
          title: "My Booking",
          to: { name: "profile-booking" },
        },
        {
          icon: "far fa-heart",
          title: "Wishlist",
          to: { name: "profile-wishlist" },
        },
        {
          title: "Settings",
          to: { name: "profile-setting" },
          component: "Settings",
        },
        // {
        //   icon: "far fa-circle-question",
        //   title: "Help Center",
        //   to: { name: "profile" },
        // },
      ];
    },
  },
  methods: {
    async signOut() {
      try {
        const check = this.$route.matched.some(
          (record) => record.components.default.options.auth !== false
        );
        if (check) {
          this.$router.push({ name: "index" });
        }
        await this.$auth.logout("laravelJWT");
      } catch (error) {}
    },
  },
};
