
import { arrayConverter, refreshTokens } from "@/utils";

export default {
  name: "DefaultLayout",
  mounted() {
    refreshTokens.call(this);
    this.$nuxt.$on("preview", this.handlePreview);

    this.$nuxt.$on("success", (success) => {
      this.$toast.success(success);
    });

    this.$nuxt.$on("error", (message) => {
      this.$toast.error(message);
    });

    this.$nuxt.$on("warning", (warning) => {
      this.$toast.warning(warning);
    });

    this.loadCarousel();
  },
  beforeDestroy() {
    this.$nuxt.$off("preview", this.handlePreview);
    this.$nuxt.$off("success");
    this.$nuxt.$off("error");
    this.$nuxt.$off("warning");
  },
  methods: {
    async loadCarousel() {},

    async loadViewer() {
      if (!this.$viewerApi) {
        await import("viewerjs/dist/viewer.css");
        const { default: VueViewer } = await import("v-viewer");
        this.$viewerApi = VueViewer;
      }
    },

    async handlePreview(images) {
      await this.loadViewer();
      this.$viewerApi({
        images: arrayConverter(images),
      });
    },
  },
};
