
export default {
  name: "MenuItem",
  props: {
    icon: String,
    title: String,
    variant: String,
    to: [String, Object],
    active: Boolean,
  },

  computed: {
    variantClass() {
      if (this.variant === "red") {
        return `text-red-600  hover:bg-red-50`;
      } else {
        return `text-gray-700 hover:text-indigo-600 hover:bg-indigo-50`;
      }
    },
    finalClass() {
      return this.active ? this.processClass() : this.variantClass;
    },
  },
  methods: {
    processClass() {
      return this.variantClass
        .split(" ")
        .filter((cls) => cls.startsWith("hover:"))
        .map((cls) => cls.replace("hover:", ""))
        .join(" ");
    },
  },
};
