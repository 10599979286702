import { render, staticRenderFns } from "./Input.vue?vue&type=template&id=2dd67ada"
import script from "./Input.vue?vue&type=script&lang=js"
export * from "./Input.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/vercel/path0/components/Button.vue').default,Button: require('/vercel/path0/components/Button.vue').default,Star: require('/vercel/path0/components/Star.vue').default,Input: require('/vercel/path0/components/Input.vue').default,Badge: require('/vercel/path0/components/Badge.vue').default,Checkbox: require('/vercel/path0/components/Checkbox.vue').default})
