import { render, staticRenderFns } from "./ShareHotelPopup.vue?vue&type=template&id=03851703"
import script from "./ShareHotelPopup.vue?vue&type=script&lang=js"
export * from "./ShareHotelPopup.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CloseButton: require('/vercel/path0/components/CloseButton.vue').default,Rating: require('/vercel/path0/components/Rating.vue').default,Input: require('/vercel/path0/components/Input.vue').default,Button: require('/vercel/path0/components/Button.vue').default,Modal: require('/vercel/path0/components/Modal.vue').default})
