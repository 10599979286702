
export default {
  name: "Footer",
  data() {
    return {
      links: [
        { name: "About us", to: { name: "about" } },
        // { name: "FAQ", to: { name: "index" } },
        // { name: "Blog", to: { name: "index" } },
        // { name: "Promotion & offers", to: { name: "index" } },
        // { name: "Support", to: { name: "index" } },
        { name: "Terms & Conditions", to: { name: "terms" } },
        { name: "Refund policy", to: { name: "refund-policy" } },
        { name: "Privacy policy", to: { name: "privacy-policy" } },
        { name: "Contact us", to: { name: "contact" } },
      ],
    };
  },
};
