
export default {
  name: "ShareHotelPopup",
  data() {
    return {
      modal: false,
      shareData: {},
      link: "",
    };
  },
  computed: {
    shareLinks() {
      return [
        {
          href: `https://www.facebook.com/sharer/sharer.php?u=${this.link}`,
          icon: "/icons/facebook.svg",
        },
        {
          href: `https://www.messenger.com/t?link=${this.link}`,
          icon: "/icons/messenger.svg",
        },
        {
          href: `https://twitter.com/intent/tweet?url=$${this.link}`,
          icon: "/icons/twitter.svg",
        },
        {
          href: `whatsapp://send?text=${this.link}`,
          icon: "/icons/whatsapp.svg",
        },
      ];
    },
  },
  watch: {
    modal(val) {
      if (!val) this.reset();
    },
  },
  mounted() {
    this.$nuxt.$on("shareHotelPopup", (data) => {
      this.setData(data);
    });
  },
  beforeDestroy() {
    this.$nuxt.$off("shareHotelPopup");
  },
  methods: {
    setData(data) {
      this.shareData = data;
      const domain = window.location.origin;
      this.link = `${domain}/hotel/${data.slug}`;
      this.modal = true;
    },
    copyUrl() {
      navigator.clipboard.writeText(this.link);
      this.$nuxt.$emit("success", "Link copied");
    },
    reset() {},
  },
};
