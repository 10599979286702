
export default {
  name: "Rating",
  props: {
    rating: { type: [String, Number], default: 0 },
    mode: { type: String, default: "star" },
    basic: Boolean,
    loading: Boolean,
    star0: Boolean,
    label: String,
  },
  methods: {
    getVariant() {
      if (this.rating === 0) {
        return "solidGreen";
      } else if (this.rating < 2.5) {
        return "solidRed";
      } else if (this.rating < 3.5) {
        return "solidAmber";
      } else if (this.rating < 4.5) {
        return "solidLime";
      } else {
        return "solidGreen";
      }
    },
    getRating(rating) {
      const floorRating = Math.floor(rating);
      const remainder = rating % 1;
      return Array.from({ length: 5 }, (_, i) => {
        if (i < floorRating) {
          return "fas fa-star";
        } else if (i === floorRating && remainder >= 0.5) {
          return "fas fa-star-half-stroke";
        } else {
          return "far fa-star";
        }
      });
    },
  },
};
