
import { mapGetters } from "vuex";
import vClickOutside from "v-click-outside";
export default {
  name: "HeadTop",
  directives: { clickOutside: vClickOutside.directive },
  data() {
    return {
      showDivision: false,
      showDistrict: false,
      showMenu: false,
    };
  },
  computed: {
    ...mapGetters(["divisions", "districts"]),
  },

  watch: {
    $route() {
      this.show = "";
      this.showMenu = false;
      this.showDivision = false;
      this.showDistrict = false;
    },
  },
};
