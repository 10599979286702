
import { initLottie } from "@/utils";

export default {
  name: "NotFound",
  data() {
    return {};
  },
  async mounted() {
    initLottie(this.$refs.error, "/lottie/404.json");
  },
  methods: {
    goBack() {
      window.history.back();
    },
  },
};
